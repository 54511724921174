<template>
  <v-dialog v-model="dialog" :max-width="width">
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn text icon color="primary" v-on="{ ...dialog, ...tooltip }">
            <fa-icon icon="shield-alt"></fa-icon>
          </v-btn>
        </template>
        <span>{{ $t("users.restrictions") }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="headline" primary-title>
        <v-layout>{{ $t("users.restrictions") }}</v-layout>
        <v-layout justify-end>
          <v-btn icon dark @click="dialog = false">
            <fa-icon icon="times" size="lg"></fa-icon>
          </v-btn>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <Restrictions
          :restrictions="userRestrictions"
          :without-header="true"
        ></Restrictions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Restrictions from "./Restrictions";
export default {
  name: "UserRestrictionModal",
  components: { Restrictions },
  props: {
    userRestrictions: {
      type: Array,
      default: () => {}
    },
    width: {
      type: Number,
      default: 450
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {}
};
</script>
