<template>
  <v-layout pt-3>
    <v-spacer></v-spacer>
    <ConfirmModal :disabled="!selected[0]" @confirm-modal="confirmModal">
      <template v-slot:default="{ on }">
        <v-btn class="light-button" :disabled="!selected[0]" v-on="on">
          <fa-icon
            :prefix="iconPrefix"
            :icon="removeIcon"
            class="mr-2"
          ></fa-icon>
          {{ $t("global.remove") }} {{ buttonsText }}
        </v-btn>
      </template>
    </ConfirmModal>
    <v-btn class="light-button" @click="addNew">
      <fa-icon :prefix="iconPrefix" :icon="addIcon" class="mr-2"></fa-icon>
      {{ $t("global.add") }} {{ buttonsText }}
    </v-btn>
  </v-layout>
</template>
<script>
import ConfirmModal from "./ConfirmModal";
export default {
  name: "AddDeleteButtons",
  components: { ConfirmModal },
  props: {
    selected: {
      type: Array,
      default: []
    },
    iconPrefix: {
      type: String,
      default: "fas"
    },
    addIcon: {
      type: String,
      default: "plus"
    },
    removeIcon: {
      type: String,
      default: "minus"
    },
    buttonsText: {
      type: String
    },
    addRoute: {
      type: String
    },
    confirmHeader: {
      type: String
    },
    confirmText: {
      type: String
    }
  },
  methods: {
    confirmModal() {
      this.$emit("confirm-remove");
    },
    addNew() {
      this.$emit("create");
    }
  }
};
</script>
