<template>
  <v-layout column>
    <UsersTable ref="usersTable"></UsersTable>
    <AddDeleteButtons
      :selected="selected"
      add-icon="user-plus"
      remove-icon="user-minus"
      :buttons-text="$t('users.user')"
      @create="onCreateClick"
      @confirm-remove="onRemoveConfirmed"
    />
  </v-layout>
</template>
<script>
import { mapState } from "vuex";
import AddDeleteButtons from "../components/AddDeleteButtons";
import UsersTable from "../components/UsersTable";
import UsersService from "../services/UsersService";
import { EmitSuccess } from "../event-bus";

export default {
  name: "Users",
  components: { AddDeleteButtons, UsersTable },
  computed: {
    ...mapState({
      selected: state => state.usersTable.selected.map(item => item.userId)
    })
  },
  methods: {
    onRemoveConfirmed() {
      UsersService.DeleteUsers(this.selected).then(response => {
        if (response.isValid) {
          EmitSuccess(this.$t("users.deleted"));
          this.$store.dispatch("SET_USERSTABLE_SELECTED", []);
          this.$refs.usersTable.getUsers();
        }
      });
    },
    onCreateClick() {
      this.$router.push({ name: "createUser" });
    }
  }
};
</script>
