var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('v-layout',{attrs:{"row":"","wrap":"","justify-end":""}},[_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('global.search'),"single-line":"","hide-details":"","clearable":"","prepend-inner-icon":"fas fa-user fa-sm"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{attrs:{"search":_vm.search,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"item-key":"userId","items-per-page":5,"show-select":""},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('UserRestrictionsModal',{attrs:{"user-restrictions":item.restrictionIds}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","icon":"","to":{
                  name: 'user',
                  params: { userId: item.userId }
                }}},on),[_c('fa-icon',{attrs:{"icon":"pencil-alt"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("global.edit")))])]),(item.isLocalUser)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","icon":"","disabled":_vm.loading},on:{"click":function($event){return _vm.generatePassword(item.userId)}}},on),[_c('fa-icon',{attrs:{"icon":"key"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("users.generatePassword")))])]):_vm._e()]}},{key:"pageText",fn:function(props){return undefined}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-progress-linear',{attrs:{"slot":"progress","indeterminate":""},slot:"progress"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }