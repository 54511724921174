<template>
  <v-flex>
    <v-layout row wrap justify-end>
      <v-flex xs12 md3>
        <v-text-field
          v-model="search"
          :label="$t('global.search')"
          single-line
          hide-details
          clearable
          prepend-inner-icon="fas fa-user fa-sm"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <v-data-table
          v-model="selected"
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="items"
          item-key="userId"
          :items-per-page="5"
          show-select
        >
          <template v-slot:header.data-table-select></template>
          <v-progress-linear slot="progress" indeterminate></v-progress-linear>
          <template v-slot:item.options="{ item }">
            <UserRestrictionsModal
              :user-restrictions="item.restrictionIds"
            ></UserRestrictionsModal>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  text
                  icon
                  :to="{
                    name: 'user',
                    params: { userId: item.userId }
                  }"
                  v-on="on"
                >
                  <fa-icon icon="pencil-alt"></fa-icon>
                </v-btn>
              </template>
              <span>{{ $t("global.edit") }}</span>
            </v-tooltip>
            <v-tooltip v-if="item.isLocalUser" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  text
                  icon
                  :disabled="loading"
                  @click="generatePassword(item.userId)"
                  v-on="on"
                >
                  <fa-icon icon="key"></fa-icon>
                </v-btn>
              </template>
              <span>{{ $t("users.generatePassword") }}</span>
            </v-tooltip>
          </template>
          <template slot="pageText" slot-scope="props"></template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-flex>
</template>
<script>
import { mapState } from "vuex";
import { Header } from "../common/Common.js";
import UsersService from "../services/UsersService.js";
import RolesService from "../services/RolesService.js";
import { EmitSuccess } from "../event-bus";
import UserRestrictionsModal from "./UserRestrictionsModal";
export default {
  name: "UsersTable",
  components: { UserRestrictionsModal },
  data() {
    return {
      search: "",
      loading: false,
      removeMode: false,
      removeDialog: false,
      availableRestrictions: [],
      options: {},
      headers: [
        new Header(this.$t("users.role"), "roleName"),
        new Header(this.$t("users.login"), "login"),
        new Header(this.$t("users.email"), "email"),
        new Header("", "options", 200)
      ],
      items: []
    };
  },
  mounted() {
    this.getUsers();
  },
  computed: {
    ...mapState({ selectedState: state => state.usersTable.selected }),
    selected: {
      get: function() {
        return this.selectedState;
      },
      set: function(newValue) {
        this.$store.dispatch("SET_USERSTABLE_SELECTED", newValue);
      }
    }
  },
  methods: {
    getUsers() {
      this.loading = true;
      UsersService.GetUsers().then(response => {
        this.items = response;
        this.loading = false;
      });
    },
    generatePassword(userId) {
      this.loading = true;
      UsersService.GenerateNewPassword(userId).then(response => {
        if (response.data) {
          EmitSuccess(this.$t("users.passwordGeneratedAndSend"));
        }
        this.loading = false;
      });
    }
  }
};
</script>
