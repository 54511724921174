<template>
  <v-layout column>
    <v-flex v-if="restrictions[0]">
      <v-subheader v-if="!withoutHeader" class="headline">{{
        $t("roles.restrictions")
      }}</v-subheader>
    </v-flex>
    <v-flex>
      <v-layout
        v-if="restrictions[0]"
        column
        pl-3
        :class="restrictionsLayoutStyle"
      >
        <div v-for="item in currentRestrictions" :key="item.restrictionId">
          <v-checkbox
            v-model="restrictions"
            :label="item.restrictionName"
            :value="item.restrictionId"
            hide-details
            class="readonly"
            color="#848484"
          ></v-checkbox>
        </div>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Restrictions",
  props: {
    restrictions: {
      type: Array,
      default: () => {
        return [];
      }
    },
    withoutHeader: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  mounted() {
    this.$store.dispatch("SET_RESTRICTIONS");
  },
  computed: {
    ...mapState({ availableRestrictions: state => state.restrictions }),
    currentRestrictions() {
      return this.availableRestrictions.filter(item => {
        return this.restrictions.indexOf(item.restrictionId) !== -1;
      });
    },
    restrictionsLayoutStyle() {
      return this.withoutHeader
        ? "restrictions-layout border"
        : "restrictions-layout";
    }
  }
};
</script>
<style lang="scss" scoped>
.fixed {
  position: fixed;
}
.readonly {
  pointer-events: none;
}
</style>
