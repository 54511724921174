import axios from "../httpConfig/http.js";
import store from "../store/index";

const urlApi = "api/users";

export default {
  GetUsers() {
    return axios.get(`${urlApi}`);
  },
  checkUserExistsInIdentity(login) {
    return axios.post(`${urlApi}/loginCheck`, {
      login: login
    });
  },
  CreateUser(login, email, roleId) {
    return axios.put(`${urlApi}`, {
      login: login,
      email: email,
      roleId: roleId,
      userName: store.state.user.login
    });
  },
  UpdateUser(login, userId, email, roleId) {
    return axios.post(`${urlApi}/${userId}`, {
      login: login,
      userId: userId,
      email: email,
      roleId: roleId,
      userName: store.state.user.login
    });
  },
  GetUserDetails(userId) {
    return axios.get(`${urlApi}/${userId}`);
  },
  DeleteUsers(users) {
    return axios.delete(`${urlApi}/`, {
      data: {
        data: users,
        userName: store.state.user.login
      }
    });
  },
  GenerateNewPassword(userId) {
    return axios.post(`${urlApi}/generateNewPassword/${userId}`, {
      userId: userId,
      userName: store.state.user.login
    });
  },
  ChangeUserPassword(currentPassword, newPassword, confirmNewPassword) {
    var userId = store.state.user.userId;
    return axios.post(`${urlApi}/changePassword/${userId}`, {
      userId: userId,
      oldPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmNewPassword
    });
  }
};
